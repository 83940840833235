import React, { useState, useLayoutEffect } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { IoIosArrowForward } from "react-icons/io";
import {
  Typography,
  Stack,
  Box, Link,
  Modal,
  TableContainer, Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, SvgIcon,
  Skeleton
} from "@mui/material";

import useAppState from "../../../../../store/appState/useAppState";
import {
  initOneTimeListenState,
  registerReferrer,
} from "../../../../../API/index";
import { getWorkerService } from "../../../../../services/workerService/workerService";
import { CopyToClipboard } from "../../../../../utilities/utilities";
import {
  ReferralsMinerInviteCard,
  ReferralsMinerWrapper,
} from "../styles/Referrals_Miner";

import { ReactComponent as PolygonDark } from "../../../../../assets/images/polygon-dark.svg";
import { ReactComponent as PolygonLight } from "../../../../../assets/images/polygon-light.svg";


import { MdCheckCircleOutline } from "react-icons/md";
import { useTypedSelector } from "../../../../../store/store";
import { ReferralsMinerRow } from "./Referrals_Miner_row";
import { useRefreshStore } from "../../../../../API/useRefreshStore";


function createData(
  address: string,
  type: string,
  status: string,
  commision: string
) {
  return { address, type, status, commision };
}

const rows = [
  createData(
    "0xC721CB9EDD9EA4BBF28B907EE3E605BDDEC450B0",
    "User",
    "active",
    `5%`
  ),
];

interface IProps {
  CNTP: string;
}

export const Referrals_Miner: React.FC<IProps> = ({ CNTP }) => {

  const intl = useIntl();
  const workservice = getWorkerService();
  const { colors } = useTheme().ui;

  const currentProfile = () => {
    if (workservice.data.status === "LOCKED") {
      return null;
    }

    return workservice.data;
  };

  const { activeProfile } = useAppState();

  const rows = [createData(currentProfile().keyID, "User", "active", `5%`)];
  const [showReferrer, setShowReferrer] = useState(currentProfile().referrer);
  const [input, setInput] = useState("");
  const [inputShowError, setInputShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const [referrerList, setReferrerList] = useState<any>([])
  // const [miningRate, setMiningRate] = useState<string>('0.000000')
  const [miningRateFree, setMiningRateFree] = useState<string>('0.000000')
  const [miningRateGuardian, setMiningRateGuardian] = useState<string>('0.000000')
  const [invitedGuardians, setInvitedGuardians] = useState<number>(0)
  const [invitedFreeUsers, setInvitedFreeUsers] = useState<number>(0)
  const authKey = useTypedSelector(state => state.appState.pass)
  const [offsetY, setOffsetY] = useState(0);
  const leaderboardStore = useRefreshStore(state => state.leaderboards)
  const refereerStore = useRefreshStore(state => state.referees)
  const walletStore = useRefreshStore(state => state.wallets)

  const getRate = () => {
    if (leaderboardStore) {
      let result = 1

      if (Number(leaderboardStore?.guardian?.referrals) > 1) {
        result = (1000 + 2 * Number(leaderboardStore?.guardian?.referrals)) / 1000
      }

      if (isNaN(result)) {
        return 1
      } else {
        return result
      }
    } else {
      return 1
    }

  }

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };


  function calculateRateFree(_leaderboard: any): string {
    //free_referrals_rate and guardians_referrals_rate will exist if the user has a referral with this plan
    if (_leaderboard) {

      const free = _leaderboard?.free_referrals_rate?.cntpRate !== undefined ? Number(_leaderboard?.free_referrals_rate?.cntpRate) : 0
      const result = (free);
      if (isNaN(result)) {
        return "0.000000";
      }
      return result.toFixed(6);
    }
    return "0.000000";
  }

  function calculateRateGuardian(_leaderboard: any): string {
    //free_referrals_rate and guardians_referrals_rate will exist if the user has a referral with this plan
    if (_leaderboard) {
      const guardians = _leaderboard?.guardians_referrals_rate?.cntpRate !== undefined ? Number(_leaderboard?.guardians_referrals_rate?.cntpRate) : 0;
      const result = (guardians);
      if (isNaN(result)) {
        return "0.000000";
      }
      return result.toFixed(6);
    }
    return "0.000000";
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const referralsLink = activeProfile
    ? window.location.origin + "/?referral=" + workservice.data?.keyID?.toLowerCase()
    : "";

  const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    setInputShowError(false);
    if (key == "Enter") {
      return _registerReferrer();
    }
  };


  async function extractInfo(obj: Array<any>) {

    if (typeof obj[0] == 'string') {
      return {
        'address': obj[0],
      };
    }

    let resultComponent = []

    obj.forEach(async (element) => {

      let returnObject = {
        'address': Object.getOwnPropertyNames(element)[0] == "0" ? Object.getOwnPropertyNames(element[0])[0] : Object.getOwnPropertyNames(element)[0]
      };
      if (Array.isArray(Object.values(element)[0])) {
        if (Object.values(element)[0].length > 0) {
          returnObject.children = await extractInfo(Array(Object.values(element)[0]))
        }
      }
      resultComponent.push(returnObject)
    });
    return resultComponent;
  }

  const _registerReferrer = async () => {
    setLoading(true);
    const [success, kkkk] = await registerReferrer(input);

    setLoading(false);
    if (success != "SUCCESS") {
      setTimeout(() => {
        setInputShowError(false);
      }, 3000);
      return setInputShowError(true);
    }
  };

  initOneTimeListenState("referrer", setShowReferrer);

  return (
    <ReferralsMinerWrapper
      style={{
        borderWidth: '1px',
        borderColor: 'blue',
        backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113",
        marginTop: '90px'
      }}
    >

      <Stack flex="1" direction="column" alignItems="flex-start" width='100%'>
        <Typography
          sx={{
            fontSize: `16px`,
            fontWeight: `500`,
            textTransform: `uppercase`,
            padding: "1rem 0 1rem 0",
            color: colors.primary,
          }}
        >
          {intl.formatMessage({ id: "platform.miner.register.referrals" })}
        </Typography>
        <Typography sx={{ textAlign: `left`, width: '100%', maxWidth: '26rem', fontSize: '36px' }}>
          Share your link and <span style={{ color: ' #8DA8FF', fontWeight: 700 }}> expand </span> your impact

          {/* {intl.formatMessage({
            id: "platform.miner.community.referralsDetail",
          })} */}
        </Typography>
        <Typography fontSize={'14px'} textAlign={'left'} marginTop={'8px'} width={'100%'} maxWidth={'26rem'}>
          Spread the wordwith our unique referral program.Share your link with friends, family, or coworkers.
        </Typography>
        <Stack gap='16px' marginTop={'32px'} fontSize={'12px'} textAlign={'left'}>
          <Stack color={colors.text.neutral} direction="row" alignItems="center" gap="0.5rem">
            <MdCheckCircleOutline />
            <Typography>
              Earn more points (CNTP)
            </Typography>
          </Stack>
          <Stack color={colors.text.neutral} direction="row" alignItems="center" gap="0.5rem">
            <MdCheckCircleOutline />
            <Typography>
              Get a 10% commission if your Invitee become a Guardian
            </Typography>
          </Stack>
        </Stack>
        <a href="../../../../../assets/pdf/The Guardian referral reward mechanism.pdf" download='The Guardian referral reward mechanism.pdf'>
          {/*           <Stack color={colors.primary} gap={'4px'} marginTop={'32px'} flexDirection={'row'} alignItems={'center'}>
            <Typography fontSize={'16px'} fontWeight={500}>Learn More</Typography>
            <IoIosArrowForward fontSize="14px" />
          </Stack> */}
        </a>
      </Stack>
      <Stack maxWidth={`30rem`} width={`100%`}>
        <ReferralsMinerInviteCard
          style={{
            backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#1b1b1d",
            border: '1px solid',
            borderColor: localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"
          }}
        >
          <Stack
            borderRadius="1rem"
            justifyContent="center"
            direction="column"
            padding="1rem"
            zIndex="2"
          >
            <Typography sx={{ fontSize: `16px`, color: colors.primary }}>
              CNTP/PER second
            </Typography>
            <Box display={'flex'} flexDirection={['column', 'row']} width={'100%'} justifyContent={'space-around'} marginTop={'16px'}>
              <Stack>
                <Typography sx={{ fontSize: "12px", letterSpacing: 1, textAlign: "left" }}>
                  Free mining
                </Typography>
                <Typography variant="h4" sx={{ letterSpacing: 1, height: `2rem` }}>
                  {leaderboardStore ? Number(leaderboardStore.free?.cntpRate) > 0 ? Number(leaderboardStore?.free?.cntpRate).toFixed(6) : Number('0').toFixed(6) : <Skeleton width={'150px'} height={'45px'} />}
                </Typography>
                <Typography sx={{ fontSize: "12px", letterSpacing: 1, textAlign: "left", marginTop: '8px' }}>
                  Online: {leaderboardStore ? leaderboardStore?.free?.referrals ? leaderboardStore?.free?.referrals : 0 : <Skeleton width={'150px'} height={'45px'} />}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontSize: "12px", letterSpacing: 1, textAlign: "left" }}>
                  Guardian mining
                </Typography>
                <Typography variant="h4" sx={{ letterSpacing: 1, height: `2rem` }}>
                  {leaderboardStore ? leaderboardStore.guardian ? Number(leaderboardStore?.guardian?.cntpRate).toFixed(6) : Number('0').toFixed(6) : <Skeleton width={'150px'} height={'45px'} />}
                </Typography>
                <Typography sx={{ fontSize: "12px", letterSpacing: 1, textAlign: "left", marginTop: '8px' }}>
                  Online: {leaderboardStore ? leaderboardStore?.guardian ? leaderboardStore?.guardian?.referrals : 0 : <Skeleton width={'150px'} height={'45px'} />}
                </Typography>
              </Stack>
            </Box>
            <Box style={{ marginTop: "8px" }}>
              <Typography sx={{ letterSpacing: 1, fontSize: "12px", marginTop: "4px" }}>
                Invited Guardians: {getRate()} x CNTP
              </Typography>
            </Box>
          </Stack>

          {/* {!showReferrer?.length && (
            <Stack spacing={1} direction="column" sx={{ width: "100%" }}>
              <Typography
                sx={{
                  textAlign: `left`,
                  fontSize: `15px`,
                  fontWeight: `600`,
                  paddingTop: `2rem`,
                }}
              >
                {intl.formatMessage({
                  id: "platform.miner.community.referralsInvite.title",
                })}
              </Typography>
              <Typography
                sx={{ textAlign: `left`, fontSize: `15px`, opacity: `0.7` }}
              >
                {intl.formatMessage({
                  id: "platform.miner.community.liveness.referrer",
                })}
              </Typography>
              <Stack direction="row" spacing={1}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{
                    color: colors.text.primary,
                    background: colors.background.elevationOne,
                    height: `2.5rem`,
                    borderRadius: `1rem !important`,
                    "*": { borderRadius: `1rem !important` },
                    overflow: `hidden`,
                  }}
                  value={input}
                  disabled={loading}
                  onChange={(e) => {
                    setInput(e.target.value);
                  }}
                  error={inputShowError}
                  onKeyUp={keyUpHandler}
                />
                {loading && (
                  <Box sx={{ display: "flex", alignItems: `center` }}>
                    <CircularProgress color="success" size="1rem" />
                  </Box>
                )}
                {!loading && !inputShowError && (
                  <Button
                    variant="outlined"
                    sx={{
                      color: colors.text.primary,
                      border: `none`,
                      width: `100%`,
                      maxWidth: `11rem`,
                      minWidth: `11rem`,
                      ":hover": {
                        background: colors.background.elevationOne,
                        border: `none`,
                      },
                    }}
                    onClick={_registerReferrer}
                  >
                    <Typography sx={{ textTransform: `none` }}>
                      {intl.formatMessage({
                        id: "platform.miner.community.liveness.registerReferrer",
                      })}
                    </Typography>
                  </Button>
                )}
              </Stack>
            </Stack>
          )} */}

          {activeProfile && (
            <>
              <Stack>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={'space-between'}
                  width="100%"
                  bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#17181F"}
                  borderRadius="1rem"
                  color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}
                  overflow="hidden"
                  padding="0.5rem 1rem"
                  marginTop="1rem"
                >
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    overflow="hidden"
                  >
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "left", fontWeight: "600" }}
                    >
                      {intl.formatMessage({
                        id: "platform.miner.community.referral.link",
                      })}
                    </Typography>
                    <Link
                      target="_blank"
                      sx={{ textDecoration: `none`, color: colors.text.primary }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "0.8rem",
                          whiteSpace: `nowrap`,
                          overflow: `hidden`,
                          textOverflow: `ellipsis`,
                        }}
                        color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}
                      >
                        {referralsLink.substring(0, 37) + '...'}
                      </Typography>
                    </Link>
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    sx={{
                      cursor: `pointer`,
                      ":hover": { filter: `brightness(1.5)` },
                    }}
                    onClick={() => CopyToClipboard(referralsLink)}
                  >
                    <Typography
                      sx={{
                        whiteSpace: `nowrap`,
                        fontSize: `16px`,
                        fontWeight: `500`,
                      }}
                    >
                      {intl.formatMessage({
                        id: "platform.miner.community.referralsInvite.copyLink",
                      })}
                    </Typography>
                  </Stack>
                </Stack>
                {
                  walletStore && walletStore[0]?.referrer ?
                    (<Typography
                      sx={{
                        whiteSpace: `nowrap`,
                        fontSize: `12px`,
                        fontWeight: `500`,
                      }}
                      marginTop={'16px'}
                      textAlign={'left'}
                      color={localStorage.getItem('mui-mode') === 'light' ? "black" : "#929092"}
                    >
                      Your Inviter: {walletStore[0]?.referrer.toLowerCase().substring(0, 5) + '...' + walletStore[0]?.referrer.toLowerCase().substring(walletStore[0]?.referrer.length - 5)}
                    </Typography>) : null
                }
                {
                  walletStore && walletStore?.referrer ?
                    (<Typography
                      sx={{
                        whiteSpace: `nowrap`,
                        fontSize: `12px`,
                        fontWeight: `500`,
                      }}
                      marginTop={'16px'}
                      textAlign={'left'}
                      color={localStorage.getItem('mui-mode') === 'light' ? "black" : "#929092"}
                    >
                      Your Inviter: {walletStore?.referrer.toLowerCase().substring(0, 5) + '...' + walletStore?.referrer.toLowerCase().substring(walletStore?.referrer.length - 5)}
                    </Typography>) : null
                }
              </Stack>
            </>
          )}


          <Stack
            alignItems="center"
            direction="row"
            padding="2rem 1rem 1rem"
            color={colors.primary}
            spacing={1}
            sx={{ cursor: `pointer`, ":hover": { filter: `brightness(0.8)` } }}
            onClick={() => setOpenModal(true)}
          >
            <Typography
              sx={{
                fontSize: `14px`,
                fontWeight: `500`,
              }}
            >
              {intl.formatMessage({
                id: "platform.miner.community.referralsInvite.seeReferrals",
              })}
            </Typography>
            <IoIosArrowForward fontSize="14px" />
          </Stack>
        </ReferralsMinerInviteCard>
      </Stack>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ margin: `auto`, bgcolor: `#'#00000066'` }}
      >
        <Stack
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: `896px`,
            maxWidth: '100vw',
            maxHeight: `80%`,
            overflow: `hidden auto`,
            bgcolor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113",
            borderRadius: `1rem`,
            boxShadow: 24,
            p: 4,
          }}>
          <TableContainer
            sx={{
              border: `1px solid black`,
              borderColor: colors.border.light,
              borderRadius: `1rem`,
            }}
          >
            <Typography sx={{ padding: '24px' }}>Your referrals</Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><span style={{ color: colors.text.neutral }}>Address</span> </TableCell>
                  <TableCell><span style={{ color: colors.text.neutral }}>Type</span></TableCell>
                  <TableCell><span style={{ color: colors.text.neutral }}>Status</span></TableCell>
                  <TableCell align="right"><span style={{ color: colors.text.neutral }}>Your Commision</span> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refereerStore && refereerStore.length > 0 && refereerStore.map((row, i) => (
                  <ReferralsMinerRow row={row} key={row + i} comission={'5%'} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Modal>
      <SvgIcon
        className="bg-icon"
        component={PolygonDark}
        inheritViewBox
        sx={{
          fontSize: `25rem`,
          position: `absolute`,
          right: `6rem`,
          top: `1rem`,
          zIndex: 1,
        }}
      />
      <SvgIcon
        className="bg-icon"
        component={PolygonLight}
        inheritViewBox
        sx={{
          fontSize: `25rem`,
          position: `absolute`,
          left: `4rem`,
          top: `4rem`,
          zIndex: 1,
        }}
      />
    </ReferralsMinerWrapper>
  );
};

export default Referrals_Miner;
